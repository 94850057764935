.toast-container {
  background-color: black !important;
  color: white !important;
  font-size: 16px;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: auto;
  min-width: 300px;
}
.Toastify__close-button--light {
  color: #ffffff !important; /* Change this to the color you want */
  opacity: 1;
}
/* .toast-container .Toastify__progress-bar {
    display: none !important;
  }
  
  .toast-container .Toastify__toast-icon {
    display: none !important;
  }
  
  .toast-container .Toastify__toast-body {
    flex: 1;
    display: flex;
    align-items: center;
  } */
